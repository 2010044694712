export const initialState = {
  searchQuery: "",
  searchResults: [],
  loading: false,
  searchQueue: null,
  selectedRecord: null,
}

const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS"
const SET_LOADING = "SET_LOADING"
const SET_SEARCH_QUEUE = "SET_SEARCH_QUEUE"
const SET_SELECTED_RECORD = "SET_SELECTED_RECORD"

export const SearchReducer = (state, action) => {
  switch (action.type) {
    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload }
    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload }
    case SET_SEARCH_QUEUE:
      return { ...state, searchQueue: action.payload }
    case SET_SELECTED_RECORD:
      return { ...state, selectedRecord: action.payload }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    default:
      return { ...initialState }
  }
}
