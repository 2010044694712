import moment from "moment"
import firebase from "firebase"
import { getSignedInUser } from "../../Auth/services/user"
import { uploadToStorage } from "../../../services/firebase/uploadToStorage"

export const addChangelog = async ({ state, action, duration, collection }) => {
  let { firstName, lastName, email } = getSignedInUser()?.userData

  let uploadedDocuments = await uploadToStorage({
    docType: "csv",
    documents: state?.documents,
    path: `/${moment().format("YYMMDD")}/`,
    docBaseName: `${firstName?.[0]?.toUpperCase()}${lastName?.toUpperCase()}`,
  })

  firebase
    .firestore()
    .collection("changelogs")
    .add({
      email,
      action,
      duration,
      collection,
      uploadedDocuments,
      timestamp: new Date(),
      identifier: `${firstName} ${lastName}`,
    })
}
