import React from "react"
import classNames from "classnames"

import styles from "./ArchiveFileRow.module.scss"

const SuccessRow = ({ removeFile, file, result, fileName }) => {
  let { uploaded, skipped, duration } = result
  return (
    <div
      className={classNames(
        "is-flex is-flex-direction-column mb-2",
        styles["containerSuccess"]
      )}
    >
      <button className="mt-1 mr-1 is-size-4" onClick={removeFile}>
        &#10006;
      </button>
      <div
        className="ml-1 is-flex is-flex-direction-column is-justify-content-center my-auto"
        style={{ width: "90%" }}
      >
        <h3 className="has-text-primary is-size-5">
          <strong>{fileName || file?.oldname}</strong> has been successfully
          uploaded
        </h3>
        <p className="has-text-grey is-size-6">
          Uploaded {uploaded} record{uploaded > 1 && "s"}
          {skipped > 1 &&
            ` and skipped ${skipped} record${skipped > 1 && "s"}`}{" "}
          in {duration}.
        </p>
      </div>
    </div>
  )
}

export default SuccessRow
