import React from "react"
import classNames from "classnames"

import styles from "./ArchiveFileRow.module.scss"

const ErrorRow = ({ removeFile, file, errors = [], validated }) => {
  return (
    <div
      className={classNames(
        "is-flex is-flex-direction-column mb-2",
        styles["containerDanger"]
      )}
    >
      <button className="mt-1 mr-1 is-size-4" onClick={removeFile}>
        &#10006;
      </button>
      <div
        className="ml-1 is-flex is-flex-direction-column is-justify-content-center my-auto"
        style={{ width: "90%" }}
      >
        <h3 className="has-text-primary is-size-5">
          There were errors found while {validated ? "uploading" : "validating"}{" "}
          <strong>{file?.oldname}</strong>
        </h3>
        <details className="has-text-grey is-size-6">
          <summary>See More Details ({errors?.length}):</summary>
          <ol
            className="mx-2"
            style={{
              maxHeight: "96px",
              overflowY: "scroll",
              listStylePosition: "inside",
            }}
          >
            {errors?.map((error) => {
              switch (error?.code) {
                case "MC404":
                  return (
                    <li>
                      Missing columns: <b>{error?.errors?.join(", ")}</b>
                    </li>
                  )
                case "MD404":
                  return (
                    <li>
                      Missing column data:
                      <ul>
                        {error?.errors?.map((rowColumn) => (
                          <li dangerouslySetInnerHTML={{ __html: rowColumn }} />
                        ))}
                      </ul>
                    </li>
                  )
                default:
                  return <li>Unknown error detected. Please report to IT.</li>
              }
            })}
          </ol>
        </details>
      </div>
    </div>
  )
}

export default ErrorRow
