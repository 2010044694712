import React from "react"
import Button from "elements/Button"
import classNames from "classnames"

import styles from "./ArchiveFileRow.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faUpload } from "@fortawesome/free-solid-svg-icons"

const DefaultRow = ({
  progress,
  validated,
  file,
  data,
  validateFile,
  removeFile,
  uploadFile,
  loading,
  converting,
  fileName,
}) => {
  const statusText = () => {
    switch (true) {
      case validated && !converting && progress === 0:
        return "Queued"
      case validated && converting:
        return "Converting " + progress.toFixed(2) + "%"
      case validated && !converting:
        return "Uploading " + progress.toFixed(2) + "%"
      default:
        return "Validating " + progress.toFixed(2) + "%"
    }
  }

  return (
    <div
      className={classNames(
        "relative is-flex  is-flex-direction-column",
        styles["container"]
      )}
    >
      <progress
        className={classNames(
          "progress is-small is-info",
          styles["progressBar"]
        )}
        value={`${progress}`}
        max="100"
      />
      {!loading && (
        <button className="mt-1 mr-1 is-size-4" onClick={removeFile}>
          &#10006;
        </button>
      )}

      <div className="is-flex is-justify-content-space-between px-1">
        <div>
          <div className="is-flex is-align-items-center is-align-content-center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              size={"2x"}
              color={validated ? "#0d6d6e" : "#D9D9D9"}
            />
            <div className="ml-1 is-flex is-flex-direction-column is-justify-content-center">
              <h3 className="has-text-primary is-size-5">
                {fileName || file?.oldname}
              </h3>
              <p className="has-text-grey-light is-size-6">
                Headers:{" "}
                {Object.keys(data?.[0] || {}).map((key) => key).length || 0}{" "}
                Rows: {data?.length}
              </p>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="is-flex is-flex-direction-column is-justify-content-center">
            <p>{statusText()}</p>
          </div>
        ) : validated ? (
          <Button
            onClick={(e) => {
              e.preventDefault()
              uploadFile()
            }}
            color={"primary"}
            style={{ margin: "auto 0", position: "relative" }}
          >
            <FontAwesomeIcon
              icon={faUpload}
              color={"#fff"}
              style={{ marginRight: "0.25rem" }}
            />
            Upload
          </Button>
        ) : (
          <Button
            onClick={validateFile}
            color={"primary"}
            style={{ margin: "auto 0", position: "relative" }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              color={"#fff"}
              style={{ marginRight: "0.25rem" }}
            />
            Validate
          </Button>
        )}
      </div>
    </div>
  )
}

export default DefaultRow
