import React, { useEffect, useReducer, useState } from "react"
import classNames from "classnames"

import FormInput from "../FormInput"
import AsyncSearchResults from "./AsyncSearchResults"

import styles from "../utils/form.module.scss"
import { initialState, SearchReducer } from "./reducer"

const AsyncSearch = (props) => {
  const [isClicked, setIsClicked] = useState(false)
  const [searchState, searchDispatch] = useReducer(SearchReducer, initialState)

  const handleSearchChange = async (event) => {
    let searchQuery = event.target.value
    searchDispatch({ type: "SET_SEARCH_QUERY", payload: searchQuery })
    searchDispatch({ type: "SET_SELECTED_RECORD", payload: null })
    if (props.onChange) props.onChange()
  }

  useEffect(() => {
    const HAS_ENTERED_SEARCH_QUERY = !!searchState?.searchQuery
    const HAS_NO_SELECTED_RECORD = !searchState?.selectedRecord

    if ((HAS_ENTERED_SEARCH_QUERY && HAS_NO_SELECTED_RECORD) || isClicked) {
      searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
      searchDispatch({ type: "SET_LOADING", payload: true })
      if (searchState?.searchQueue) clearTimeout(searchState?.searchQueue)

      switch (true) {
        default:
        case props?.options?.length > 0:
          searchDispatch({
            type: "SET_SEARCH_QUEUE",
            payload: setTimeout(async () => {
              let searchResults = props?.options?.filter((option) =>
                option[props?.optionKey || "Value"]
                  ?.toLowerCase()
                  .includes(searchState?.searchQuery?.toLowerCase().trim())
              )

              searchDispatch({
                type: "SET_SEARCH_RESULTS",
                payload: searchResults.map((searchResult) => ({
                  primaryValue: searchResult,
                  secondaryFields: props.secondaryFields.map(
                    (secondaryField) => ({
                      label: secondaryField,
                      value: searchResult,
                    })
                  ),
                })),
              })
              searchDispatch({ type: "SET_SELECTED_RECORD", payload: null })
              searchDispatch({ type: "SET_LOADING", payload: false })
            }, 1000),
          })
          break
      }
    } else {
      searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
      searchDispatch({ type: "SET_SELECTED_RECORD", payload: null })
    }

    // eslint-disable-next-line
  }, [isClicked, searchState.searchQuery])

  const handleRecordClick = (searchResult) => {
    let finalSearchQuery = searchResult
    if (props?.table)
      finalSearchQuery = searchResult?.fields[props.searchKeys[0]]

    searchDispatch({ type: "SET_SEARCH_QUERY", payload: finalSearchQuery })
    searchDispatch({ type: "SET_SELECTED_RECORD", payload: searchResult })
    searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })

    let selectedValue =
      finalSearchQuery?.primaryValue?.[props?.labelKey || props?.optionKey]
    props.setFieldValue(props.name, selectedValue)
    if (props.handleRecordClick) props.handleRecordClick(selectedValue)

    setIsClicked(false)
  }

  useEffect(() => {
    searchDispatch({
      type: "SET_SELECTED_RECORD",
      payload: props.initialSelectedRecord || null,
    })
    searchDispatch({
      type: "SET_SEARCH_QUERY",
      payload: props.initialSearchQuery || "",
    })
  }, [props.initialSearchQuery, props.initialSelectedRecord])

  return (
    <section
      className={classNames(styles["searchAsync"], props.className || "mb-3")}
    >
      <div className="">
        <FormInput
          value={
            searchState?.searchQuery?.primaryValue?.[props?.optionKey] ||
            searchState?.searchQuery?.primaryValue
          }
          onClick={() => {
            setIsClicked(true)
          }}
          loading={searchState?.loading}
          helper={props.helper}
          label={props.label}
          onChange={handleSearchChange}
          name={`${props.name}`}
          errors={props.errors}
          isRequired={props?.isRequired}
        />
      </div>
      <AsyncSearchResults
        optionKey={props?.labelKey || props?.optionKey}
        searchResults={searchState.searchResults}
        handleRecordClick={handleRecordClick}
      />
    </section>
  )
}

export default AsyncSearch
