import processBatch from "./processBatch"
import updateDocument from "./updateDocument"
import insertDocument from "./insertDocument"
import { addChangelog } from "./addChangelog"
import { compileDocuments } from "./compileDocuments"
import msToTime from "../../Extractor/utils/msToTime"
import { getSignedInUser } from "../../Auth/services/user"

/**
 * @param {Array<String>} data [Raw data retrieved from the CSV]
 * @param {Array<String>} headers [An array of the list of template headers]
 * @param {String} type [Chosen group type from the Configuration]
 * @param {Function} setProgress [Setter Function that updates the progress of the process]
 * @param {Function} errorCallBack [Callback function called when the process was able to finish with error/s]
 * @param {Function} successCallback [Callback function called when the process was able to finish without an error]
 * @param {Object} config [Configuration object sourced from Airtable]
 * @returns {Array<Object>} Structured document based on the configuration
 */
export const uploadToFirebase = async ({
  data,
  type,
  state,
  headers,
  setProgress,
  errorCallback,
  successCallback,
  config,
}) => {
  let userDocument = getSignedInUser().userData
  let { allowedCollections } = userDocument

  try {
    // parse orders and medicines
    let totalUploaded = 0
    let totalProgress = 0
    let start = new Date()
    let mgClients = ["mg employee", "aon", "scpwd", "regular", "bestlife"]

    let compiledDocuments = compileDocuments({
      data,
      headers,
      type,
      config,
    })

    let batchRefs = []
    let collection = config[type].collections[config[type].mainCollection]

    if (allowedCollections?.includes(collection?.Name)) {
      switch (config[type].action.toUpperCase()) {
        case "UPDATE":
          for (const document of compiledDocuments) {
            batchRefs = await updateDocument({
              type,
              config,
              document,
              batchRefs,
              collection,
              userDocument,
            })
            totalUploaded++
          }

          break
        case "INSERT":
        default:
          for (const document of compiledDocuments) {
            document.programCode = state?.programCode

            document.documentType = "order"
            if (document?.batch?.toLowerCase().includes("refill"))
              document.documentType = "refill"

            if (mgClients?.includes(document?.client?.toLowerCase()))
              document.zendeskId = `W${document?.zendeskId}`

            batchRefs = insertDocument({
              type,
              config,
              document,
              batchRefs,
              collection,
              userDocument,
            })
            totalUploaded++
            totalProgress += 1
            setProgress((totalProgress / compiledDocuments?.length) * 100)
          }
      }

      let batches = processBatch(batchRefs, config[type].action.toUpperCase())
      await Promise.all(batches)

      // add changelog document reference
      addChangelog({
        state,
        collection: collection?.Name,
        duration: msToTime(new Date() - start),
        action: config[type].action.toUpperCase(),
      })

      if (successCallback) {
        successCallback({
          skipped: compiledDocuments?.length - totalUploaded,
          uploaded: totalUploaded,
          duration: msToTime(new Date() - start),
        })
      }
    } else {
      throw Error(
        `User has no permission to upload to "${collection?.Name}" collection.`
      )
    }
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }
}
