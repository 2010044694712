import React, { useContext, useState } from "react"

import ArchiverUpload from "./ArchiverUpload"
import ArchiverVerify from "./ArchiverVerify"
import PrivateRoute from "../PrivateRoute"
import { AppContext } from "../../context/AppContext"

const UPLOAD_VIEW = "UPLOAD_VIEW"
const VERIFY_VIEW = "VERIFY_VIEW"

const Archiver = ({ isPrivate }) => {
  const { state, dispatch } = useContext(AppContext)
  const [view, setView] = useState(UPLOAD_VIEW)

  switch (view) {
    case VERIFY_VIEW:
      return (
        <PrivateRoute isPrivate={isPrivate}>
          <ArchiverVerify setView={setView} state={state} dispatch={dispatch} />
        </PrivateRoute>
      )
    default:
    case UPLOAD_VIEW:
      return (
        <PrivateRoute isPrivate={isPrivate}>
          <ArchiverUpload setView={setView} state={state} dispatch={dispatch} />
        </PrivateRoute>
      )
  }
}

export default Archiver
