import React from "react"
import classNames from "classnames"

import styles from "../utils/form.module.scss"

const AsyncSearchResults = ({
  optionKey,
  searchResults,
  handleRecordClick,
}) => {
  if (searchResults?.length > 0)
    return (
      <div className={classNames(styles["searchResults__async"])}>
        {searchResults.map((searchResult) => (
          <div
            className="box"
            role="button"
            tabIndex={0}
            onKeyDown={() => handleRecordClick(searchResult)}
            onClick={() => handleRecordClick(searchResult)}
          >
            <p className="has-text-weight-bold mb-2">
              {searchResult.primaryValue?.[optionKey]}
            </p>
            <div className="columns">
              {searchResult.secondaryFields
                .slice(0, 4)
                .map((secondaryField) => (
                  <div className="column is-3">
                    <p className="has-text-grey is-uppercase is-size-7">
                      {secondaryField.label}
                    </p>
                    <p className="is-size-7">
                      {secondaryField.value[secondaryField.label]}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    )
  return null
}

export default AsyncSearchResults
