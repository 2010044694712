import { parseFirebaseValue } from "./parseFirebaseValue"

/**
 *
 * @param {Array<String>} data [Raw data retrieved from the CSV]
 * @param {Array<String>} headers [An array of the list of template headers]
 * @param {Object} config [Configuration object sourced from Airtable]
 * @param {String} type [Chosen group type from the Configuration]
 * @returns {Array<Object>} Structured document based on the configuration
 */

export const compileDocuments = ({ data, headers, type, config }) => {
  const TYPE_CONFIG = config[type]
  const PARENT_COLLECTION = TYPE_CONFIG.collections[TYPE_CONFIG.mainCollection]

  let headerMapping = {}
  headers.forEach((header) => {
    headerMapping[header.toLowerCase()] = headers.indexOf(header)
  })

  return createDocumentObjects({
    data,
    collection: PARENT_COLLECTION,
    headers,
    headerMapping,
    config,
    type,
  })
}

/**
 *
 * @param {Array<String>} data [Raw data retrieved from the CSV]
 * @param {Object} collections [Main collection to be used in mapping the fields of the object]
 * @param {Array<String>} headers [An array of the list of template headers]
 * @param {Object} headerMapping [A mapping between the template headers and its index in the raw data]
 * @param {Object} config [Configuration object sourced from Airtable]
 * @param {String} type [Chosen group type from the Configuration]
 * @returns {Array<Object>} Structured document based on the configuration
 */

const createDocumentObjects = ({
  data,
  collection,
  headers,
  headerMapping,
  config,
  type,
}) => {
  let firestoreKey = config[type].firestoreKey
  let dataTypes = config[type].dataTypes
  let key = getUniqueKeyIndex(headers, collection.key, firestoreKey)
  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null
  let allUniqueDocs = [...new Set(data?.map((data) => data[key]))]

  let arr = allUniqueDocs.map((id) => {
    let parsedDoc = {}
    let documents = data.filter((item) => item[key] === id)

    documents.forEach((doc) => {
      collection.data.forEach((field) => {
        let fieldValue

        for (const candidateValue of getValByKey(firestoreKey, field)) {
          if (doc[headerMapping[candidateValue.toLowerCase()]])
            fieldValue = doc[headerMapping[candidateValue.toLowerCase()]]
        }

        if (
          (!fieldValue && config[type].action.toUpperCase() === "UPDATE") ||
          (fieldValue === "N/A" &&
            config[type].action.toUpperCase() === "UPDATE")
        )
          return
        parsedDoc[field] = fieldValue
          ? parseFirebaseValue({
              value: fieldValue,
              dataType: dataTypes[field],
              type,
            })
          : null
      })
    })
    if (subcollection) {
      parsedDoc[subcollection.Name] = createDocumentObjects({
        data: documents,
        collection: subcollection,
        headers,
        headerMapping,
        config,
        type,
      })
    }

    return parsedDoc
  })
  return arr
}
/**
 *
 * @param {Array<String>} headers [The list of template headers]
 * @param {String} key [The key to be searched for]
 * @param {Object} firestoreKey [Object of the sourced Firestore Key - Template Header Mapping]
 * @returns {Number} [index of the unique key]
 */

export const getUniqueKeyIndex = (headers, key, firestoreKey) =>
  headers.indexOf(
    headers.find((header) => {
      let candidateKeys = getValByKey(firestoreKey, key)
      for (const candidate of candidateKeys)
        if (candidate.toLowerCase() === header.toLowerCase()) {
          return header
        }
      return null
    })
  )

/**
 *
 * @param {Object} obj [Object to be mapped to find the value]
 * @param {Any} value [Value to be searched in the obj]
 * @returns {Array<Number>} [index of the unique key]
 */

const getValByKey = (obj, value) => {
  return Object.keys(obj).filter(
    (key) => obj[key].toLowerCase() === value.toLowerCase()
  )
}
