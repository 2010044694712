import * as _ from "lodash"
import firebase from "firebase"
/**
 *
 * @param {Array<FirestoreRefObject>} batchRefs [Array of firestore references]
 * @param {String} type [Chosen group type from the Configuration]
 * @param {Number} size [size of the batch. Default at 500]
 * @returns {Array} array of chunks containing the references
 */

const processBatch = (batchRefs, type, size = 500) => {
  let batches = _.chunk(batchRefs, size).map((chunk) => {
    let batch = firebase.firestore().batch()

    chunk.forEach((ref) => {
      if (type === "UPDATE") batch.update(ref.ref, { ...ref.data })
      else batch.set(ref.ref, { ...ref.data })
    })
    return batch.commit()
  })

  return batches
}

export default processBatch
