import React from "react"

const ArchiverCustomToolbar = ({
  dispatch,
  orderType,
  displayData,
  selectedRows,
}) => {
  const handleSaveOverwrites = () => {
    let ordersToUpdate = []
    let dataIndexes = selectedRows?.data?.map((row) => row?.dataIndex)

    let keyIndex = 1
    if (orderType === "Refill") keyIndex = 7

    displayData.forEach((row) => {
      if (dataIndexes?.includes(row?.dataIndex))
        ordersToUpdate.push(row?.data?.[keyIndex])
    })

    dispatch({ type: "SAVE_TO_UPDATE_ORDERS", payload: ordersToUpdate })
  }

  return (
    <div class="buttons are-regular mr-2">
      <button
        onClick={handleSaveOverwrites}
        className="button is-primary is-outlined"
      >
        Overwrite
      </button>
      {/* <button className="button is-primary">Skip</button> */}
    </div>
  )
}

export default ArchiverCustomToolbar
