import { useStaticQuery, graphql } from "gatsby"

const ALL_PROGRAM_CODE_KEY = graphql`
  query programCodeQuery {
    allAirtableProgramCodes {
      nodes {
        data {
          Program_Code
          Name
          Module
          Client
          Programs
          Software
          Search_Keys
          Additional_Search_Keys
        }
      }
    }
  }
`

export const useProgramCodes = () => {
  let data = useStaticQuery(ALL_PROGRAM_CODE_KEY)

  return data?.allAirtableProgramCodes?.nodes
}
