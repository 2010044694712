import processBatch from "./processBatch"
import updateDocument from "./updateDocument"
import insertDocument from "./insertDocument"
import { compileDocuments } from "./compileDocuments"
import msToTime from "../../../../Extractor/utils/msToTime"
import timeout from "../../../../Extractor/utils/timeout"
import { addChangelog } from "../../../../Archiver/services/addChangelog"

/**
 *
 * @param {Array<String>} data [Raw data retrieved from the CSV]
 * @param {Array<String>} headers [An array of the list of template headers]
 * @param {String} type [Chosen group type from the Configuration]
 * @param {Function} setProgress [Setter Function that updates the progress of the process]
 * @param {Function} errorCallBack [Callback function called when the process was able to finish with error/s]
 * @param {Function} successCallback [Callback function called when the process was able to finish without an error]
 * @param {Object} config [Configuration object sourced from Airtable]
 * @returns {Array<Object>} Structured document based on the configuration
 */

export const uploadToFirebase = async ({
  data,
  headers,
  type,
  mutex,
  setConverting,
  setProgress,
  errorCallback,
  successCallback,
  config,
  state,
}) => {
  try {
    // parse orders and medicines
    const CHUNK_SIZE = 54
    const CREATING_BATCH_REFS = 83.33
    const PROCESSING_BATCHES = 16.67
    let totalUploaded = 0
    let totalProgress = 0

    let compiledDocuments = await Promise.all(
      compileDocuments({
        data,
        headers,
        type,
        config,
        setProgress,
      })
    )

    setProgress(0)
    setConverting(false)
    await mutex.lock()
    let start = new Date()

    let batchRefs = []
    let collection = config[type].collections[config[type].mainCollection]

    switch (config[type].action.toUpperCase()) {
      case "UPDATE":
        for (const document of compiledDocuments) {
          let client = state?.programCode?.substring(0, 3)

          if (client === "MGX") {
            delete document.employeeNumber
          }

          if (document.employeeNumber)
            document.employeeNumber = document.employeeNumber
              .toString()
              .replace(".0", "")
          if (document.mobileNumber)
            document.mobileNumber = String(document.mobileNumber).replace(
              ".0",
              ""
            )
          if (document.employeeNumber === "") delete document.employeeNumber

          batchRefs = await updateDocument({
            document,
            collection,
            config,
            type,
            batchRefs,
          })
          if (totalProgress % CHUNK_SIZE === 0) {
            setProgress(((totalProgress + 1) / compiledDocuments?.length) * 100)
            await timeout(0)
          }
          totalUploaded++
          totalProgress += 1
        }

        break
      case "INSERT":
      default:
        for (const document of compiledDocuments) {
          document.programCode = state?.programCode
          let client = state?.programCode?.substring(0, 3)

          document.documentType = "order"
          if (
            document?.batch?.toLowerCase().includes("refill") ||
            document?.batch?.toLowerCase().includes("archived")
          ) {
            document.documentType = "refill"
            document.orderType = "ME Refills"
          }

          if (client === "MGX") {
            document.zendeskId = `W${document?.zendeskId}`
            document.orderType = "FMC"
          }

          document.zendeskId = String(document.zendeskId).replace(".0", "")

          batchRefs = insertDocument({
            document,
            collection,
            config,
            type,
            batchRefs,
            client,
          })
          if (totalProgress % CHUNK_SIZE === 0) {
            setProgress(
              ((totalProgress + 1) / compiledDocuments?.length) *
                CREATING_BATCH_REFS
            )
            await timeout(10)
          }
          totalUploaded++
          totalProgress += 1
        }
    }

    setProgress(CREATING_BATCH_REFS)

    let batches = processBatch(batchRefs, config[type].action.toUpperCase())

    let totalBatchProgress = 0

    for (const batch of batches) {
      await batch
      setProgress(
        ((totalBatchProgress + 1) / batches.length) * PROCESSING_BATCHES +
          CREATING_BATCH_REFS
      )
      totalBatchProgress++
    }
    if (successCallback) {
      let duration = msToTime(new Date() - start)

      mutex.release()
      successCallback({
        duration,
        uploaded: totalUploaded,
        skipped: compiledDocuments?.length - totalUploaded,
      })

      // add changelog document reference
      addChangelog({
        state,
        duration,
        collection: collection?.Name,
        action: config[type].action.toUpperCase(),
      })
    }
  } catch (error) {
    mutex.release()
    if (errorCallback) errorCallback(error)
  }
}
