import { checkIfAlreadyUploaded } from "./checkIfAlreadyUploaded"
import { GATSBY_MISSING_COL, GATSBY_MISSING_DATA } from "gatsby-env-variables"

export const verifyCSV = async ({
  type,
  document,
  setProgress,
  errorCallback,
  successCallback,
  config,
}) => {
  try {
    type = type?.value
    let errors = []
    let data = document?.data || []
    let headers =
      Object.keys(document?.[0]).map((key) => key.toLowerCase()) || []

    let missingColumns = getMissingColumns(headers, config, type)
    let missingData = getMissingData(headers, data, config, type)

    let duplicateOrders = await checkIfAlreadyUploaded({
      data,
      setProgress,
      orderType: type,
    })

    if (missingColumns?.length > 0)
      errors.push({ code: GATSBY_MISSING_COL, errors: missingColumns })
    if (missingData?.length > 0)
      errors.push({ code: GATSBY_MISSING_DATA, errors: missingData })

    if (successCallback) successCallback(errors, duplicateOrders)
  } catch {
    if (errorCallback) errorCallback()
  }
}

// Check all required columns
const getMissingColumns = (headers, config, type) => {
  let requiredColumns = config[type].requiredColumns

  let missingColumns = []

  requiredColumns.forEach((column) => {
    if (!headers?.includes(column.toLowerCase())) missingColumns.push(column)
  })

  return missingColumns
}

// Check data of required columns
const getMissingData = (headers, data, config, type) => {
  let requiredColumns = config[type].requiredColumns
  let headerMapping = {}
  let missingData = []

  // map headers
  headers.forEach((header) => {
    headerMapping[header] = headers.indexOf(header)
  })

  data.forEach((value, index) => {
    let columnsWithErrors = []
    let tempRequiredColumns = [...requiredColumns]
    let orderType = value[headerMapping["OrderType"]]

    if (orderType?.toUpperCase() === "ME") tempRequiredColumns.push("DrugName")
    else if (orderType?.toUpperCase() === "FMC")
      tempRequiredColumns.push("SKUTitle")

    tempRequiredColumns.forEach((column) => {
      if (!value[headerMapping[column]]) columnsWithErrors.push(column)
    })

    if (columnsWithErrors?.length > 0)
      missingData.push(
        `<b>${columnsWithErrors?.join(", ")}</b> on row ${index + 1}`
      )
  })

  return missingData
}
