import React, { Fragment, useState, useRef } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import Papa from "papaparse"
import lodash from "lodash"

import Layout from "layout"
import Message from "elements/Message"
import Section from "elements/Section"
import Container from "layout/Container"
import ErrorBuilder from "./ErrorBuilder"
import FormSelect from "elements/Form/FormSelect"
import FormAsyncSearch from "elements/Form/FormAsyncSearch"
import Dropzone from "elements/UploadDocumentDropzone/Dropzone"

import { isBrowser } from "services/general"
import { useConfig } from "../../context/hooks/useConfig"
import { useProgramCodes } from "../../context/hooks/useProgramCodes"
import { uploaderValidationSchema } from "./utils/uploaderValidationSchema"
import ArchiveFileRow from "../Elements/UploadDocumentDropzone/ArchiveFileRow"
import Mutex from "../Elements/UploadDocumentDropzone/ArchiveFileRow/utils/mutex"

const ArchiverUpload = ({ state, dispatch }) => {
  const mutex = useRef(new Mutex())

  const [progress] = useState(0)
  const [csvErrors] = useState([])
  const [csvHeaders, setCSVHeaders] = useState([])
  const [collectionBU, setCollectionBU] = useState(null)

  let programCodes = useProgramCodes()
  programCodes = programCodes?.map((programCode) => programCode?.data)

  let config = useConfig()
  let options = Object.keys(config).map((key) => {
    return { value: key, label: key }
  })

  let enrollment = {}
  if (isBrowser())
    enrollment = JSON.parse(sessionStorage?.getItem("enrollment"))
  options = options?.filter((option) =>
    enrollment?.allowedDataSets?.includes(option?.value)
  )

  if (
    isBrowser() &&
    !enrollment?.allowedPaths?.includes(window?.location?.pathname)
  )
    navigate("/")

  const handleUploadSuccess = async ({ file }) => {
    let partitionedData, csvHeaders
    const PARTITION_LENGTH = 1000

    Papa.parse(file?.file, {
      header: true,
      complete: (csv) => {
        partitionedData = lodash.chunk(csv.data, PARTITION_LENGTH)
        csvHeaders = Object.keys(csv.data?.[0]).map((item) => item)

        setCSVHeaders(csvHeaders)
        dispatch({
          type: "SAVE_PARTITIONED_DOCUMENT",
          payload: partitionedData,
        })
        dispatch({
          type: "SAVE_DOCUMENT",
          payload: file,
        })
      },
    })
  }

  return (
    <Layout
      seoTitle="Archiver"
      progress={progress}
      title="MG Kit Cloud Archiver"
      display={{ helpCenterBanner: false, footer: false }}
      subtitle="Upload data from Airtable and Google Sheets to the cloud"
    >
      <Container isCentered desktop={8} fullhd={7}>
        <div className="mb-3">
          <Message color="primary">
            <div className="content">
              <p className="is-size-6">
                In case something goes wrong, please fill out and submit an
                issue form to:{" "}
                <a
                  className="has-text-weight-bold"
                  target="__blank"
                  href="https://mdgr.cr/IT"
                >
                  mdgr.cr/IT
                </a>
              </p>
            </div>
          </Message>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            type: state?.type,
            headers: [],
            isUploadingWeb: state?.isUploadingWeb,
            programCode: "",
          }}
          validationSchema={uploaderValidationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Section
                title="Select upload type"
                subtitle="Select which kind of data you are archiving"
                addOns={{ left: 1 }}
              >
                {collectionBU && (
                  <Message color="primary">
                    <div className="content">
                      <p className="is-size-6">
                        You may download a copy of the template{" "}
                        <a
                          href="/data/template-csv-uploader.csv"
                          download={`${collectionBU}-template-csv-uploader`}
                          className="mt-1"
                        >
                          here
                        </a>
                      </p>
                    </div>
                  </Message>
                )}
                <FormSelect
                  name={"type"}
                  isRequired
                  onChange={(value) => {
                    dispatch({ type: "SAVE_TYPE", payload: value })

                    let collectionSelected = value?.label
                    let collection =
                      config[collectionSelected]?.collections[
                        config[collectionSelected]?.mainCollection
                      ]
                    let collectionBU = collection?.bu

                    setCollectionBU(collectionBU)
                  }}
                  options={options}
                  value={values?.type}
                  setFieldValue={setFieldValue}
                />
              </Section>
              <Section
                title="Select program code"
                subtitle="Select the program of the data you are trying to upload"
                addOns={{ left: 2 }}
              >
                <FormAsyncSearch
                  isRequired
                  optionKey="Search_Keys"
                  labelKey="Name"
                  name="programCode"
                  value={values?.type}
                  options={programCodes}
                  setFieldValue={setFieldValue}
                  handleRecordClick={(value) => {
                    dispatch({ type: "SAVE_PROGRAM_CODE", payload: value })
                  }}
                  secondaryFields={["Client", "Module", "Programs"]}
                />
              </Section>
              <Section
                title="Upload CSV"
                subtitle="Please make sure that the headers match the template"
                addOns={{ left: 3 }}
              >
                {state?.partitionedDocuments?.length > 0 ? (
                  <Fragment>
                    <div className="mt-2 mb-2">
                      {state?.partitionedDocuments.map((file, index) => (
                        <ArchiveFileRow
                          key={file?.path}
                          file={file}
                          headers={csvHeaders}
                          fileName={`${state?.documents?.[0]?.oldname} part ${index}`}
                          dispatch={dispatch}
                          state={state}
                          config={config}
                          mutex={mutex.current}
                        />
                      ))}
                    </div>
                    {csvErrors?.length > 0 && (
                      <ErrorBuilder errors={csvErrors} />
                    )}
                  </Fragment>
                ) : (
                  <Dropzone
                    docType={"csv"}
                    maxFileCount={1}
                    icon={"document"}
                    label={"Upload CSV"}
                    currentFilesList={state?.documents}
                    onUploadSuccess={handleUploadSuccess}
                  />
                )}
              </Section>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default ArchiverUpload
