import moment from "moment"
import firebase from "firebase"

/**
 * @param {String} type [Chosen group type from the Configuration]
 * @param {Object} config [Configuration object sourced from Airtable]
 * @param {Object} document [An object of the compiled document which is equivalent to a record of the collection]
 * @param {Object} collection [collection configuration used to determine the next reference]
 * @param {QuerySnapshot} reference [The reference to the specific firestore object]
 * @param {Array<FirestoreRefObject>} batchRefs [An array of firestore references]
 * @returns {Array<RefObject>} batchRefs [An updated array of firestore references]
 */
const insertDocument = ({
  type,
  config,
  document,
  batchRefs,
  collection,
  userDocument,
  reference = null,
}) => {
  let { firstName, lastName, email } = userDocument
  let now = new Date()

  let isTopLevel = reference ? false : true
  if (isTopLevel) {
    reference = firebase.firestore().collection(collection.Name)
  } else {
    reference = reference.collection(collection.Name)
  }

  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  let subcollectionDocument
  if (subcollection) {
    subcollectionDocument = document[subcollection.Name]
    delete document[subcollection.Name]
  }
  reference = reference.doc()
  batchRefs.push({
    ref: reference,
    data: {
      ...document,
      _changelog: [
        {
          updatedBy: firstName + " " + lastName,
          email,
          updatedTime: now,
          updatedFields: document,
        },
      ],
      migrationTag: [`MIGRATION-${moment().format("YYMMDD")}`],
      _tags: [{ timestamp: now, action: "Insert" }],
    },
  })
  if (subcollection) {
    for (const doc of subcollectionDocument) {
      batchRefs = insertDocument({
        document: doc,
        collection: subcollection,
        config,
        type,
        batchRefs,
        reference,
      })
    }
  }
  return batchRefs
}

export default insertDocument
