import React, { useState } from "react"

import DefaultRow from "./DefaultRow"
import ErrorRow from "./ErrorRow"
import SuccessRow from "./SuccessRow"

import { verifyCSV } from "./services/verifyCSV"
import { uploadToFirebase } from "./services/uploadToFirebase"

const ArchiveFileRow = ({
  file,
  state,
  dispatch,
  config,
  mutex,
  headers,
  fileName,
}) => {
  const [loading, setLoading] = useState(false)
  const [converting, setConverting] = useState(false)
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState("DEFAULT")
  const [validated, setValidated] = useState(false)
  const [errors, setErrors] = useState([])
  const [result, setResult] = useState({ skipped: 0, upload: 0, duration: 0 })

  const removeFile = (event) => {
    event.preventDefault()
    dispatch({ type: "REMOVE_DOCUMENT", payload: file })
  }

  const uploadFile = async () => {
    setConverting(true)
    setLoading(true)
    await uploadToFirebase({
      state,
      mutex,
      data: file,
      headers,
      type: state?.type?.value || state?.type,
      setProgress,
      setConverting,
      numberOfFiles: state?.documents?.length || 1,
      errorCallback: (error) => {
        setStatus("ERROR")
        setErrors([...errors, error?.message])
      },
      successCallback: ({ skipped, uploaded, duration }) => {
        setStatus("SUCCESS")
        setResult({ skipped, uploaded, duration })
      },
      config,
    })
  }

  const validateFile = (event) => {
    event.preventDefault()
    setLoading(true)

    verifyCSV({
      setProgress,
      type: state?.type,
      document: file,
      successCallback: (errors, duplicateOrders) => {
        setLoading(false)
        dispatch({ type: "SAVE_DUPLICATE_HEADERS", payload: duplicateOrders })

        if (errors?.length > 0) {
          setStatus("ERROR")
          setErrors(errors)
        } else {
          setValidated(true)
        }
      },
      errorCallback: (error) => {
        setLoading(false)
      },
      config,
    })
  }

  switch (status) {
    case "SUCCESS":
      return (
        <SuccessRow
          fileName={fileName}
          removeFile={removeFile}
          file={file}
          result={result}
        />
      )
    case "ERROR":
      return (
        <ErrorRow
          removeFile={removeFile}
          errors={errors}
          file={file}
          validated={validated}
        />
      )
    case "DEFAULT":
    default:
      return (
        <DefaultRow
          uploadFile={uploadFile}
          validateFile={validateFile}
          removeFile={removeFile}
          progress={progress}
          validated={validated}
          file={file}
          fileName={fileName}
          data={file}
          loading={loading}
          converting={converting}
        />
      )
  }
}

export default ArchiveFileRow
