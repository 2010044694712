import firebase from "firebase"
import { getSignedInUser } from "../../../../Auth/services/user"

/**
 *
 * @param {Object} document [An object of the compiled document which is equivalent to a record of the collection]
 * @param {Object} collection [collection configuration used to determine the next reference]
 * @param {Object} config [Configuration object sourced from Airtable]
 * @param {String} type [Chosen group type from the Configuration]
 * @param {Array<FirestoreRefObject>} batchRefs [An array of firestore references]
 * @param {QuerySnapshot} reference [The reference to the specific firestore object]
 * @returns {Array<RefObject>} batchRefs [An updated array of firestore references]
 */

const updateDocument = async ({
  document,
  collection,
  config,
  type,
  batchRefs,
  reference = firebase.firestore(),
}) => {
  let keyDocuments, subcollection, docId, docData
  let { firstName, lastName, email } = getSignedInUser().userData
  let now = new Date()

  if (
    collection?.subcollections?.length &&
    collection?.subcollections?.[0]?.name in config?.[type]?.collections
  )
    subcollection = config[type].collections[collection.subcollections[0]?.name]

  if (!!document[collection.key]) {
    keyDocuments = await reference
      .collection(collection.Name)
      .where(collection.key, "==", document[collection.key])
      .get()

    for (let doc of keyDocuments.docs) {
      docId = doc.id
      docData = doc.data()

      if (docId) {
        let newReference = reference.collection(collection.Name).doc(docId)

        if (subcollection) {
          for (const doc of document[subcollection.Name]) {
            batchRefs = await updateDocument({
              document: doc,
              collection: subcollection,
              config,
              type,
              batchRefs,
              newReference,
            })
          }
        } else {
          if (docId) {
            batchRefs.push({
              ref: newReference,
              data: {
                ...document,
                _changelog: [
                  ...docData?._changelog,
                  {
                    updatedBy: firstName + " " + lastName,
                    email,
                    updatedTime: now,
                    updatedFields: document,
                  },
                ],
                _tags: [
                  ...docData?._tags,
                  { timestamp: now, action: "Update" },
                ],
              },
            })
          }
        }
      }
    }
  }
  return batchRefs
}

export default updateDocument
