import React, { Fragment, useState } from "react"

import Layout from "layout"
import Loading from "elements/Loading"
import Datatable from "elements/Datatable"
import ActionButtons from "elements/ActionButtons"
import ArchiverCustomToolbar from "./ArchiverCustomToolbar"

import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { uploadToFirebase } from "./services/uploadToFirebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useConfig } from "../../context/hooks/useConfig"
import { getUniqueKeyIndex } from "./services/compileDocuments"

const ArchiverVerify = ({ setView, state, dispatch }) => {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  let config = useConfig()

  let duplicateOrders = state?.duplicateOrders
  let parsedDocument = state?.parsedDocument
  let updateOrders = state?.updateOrders
  let type = state?.type?.value

  let headers = parsedDocument?.headers || []
  let data = parsedDocument?.data || []

  let uniqueHeaderKey = getUniqueKeyIndex(
    headers,
    config[type].collections[config[type].mainCollection].key,
    config[type].firestoreKey
  )

  let tableHeaders = ["Uploaded", ...headers]
  let tableData = data.map((value) => {
    let tag = ""
    if (updateOrders.includes(value[uniqueHeaderKey]))
      tag = { type: "tag", data: "Overwrite", color: "warning" }
    else if (duplicateOrders.includes(value[uniqueHeaderKey]))
      tag = { type: "tag", data: "Uploaded", color: "danger" }
    else tag = { type: "tag", data: "New", color: "primary" }

    return [tag, ...value]
  })

  const errorCallback = (error) => {
    dispatch({ type: "HIDE_MODAL" })
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "An error has occurred",
        isCard: true,
        headerClass: `has-text-danger has-background-info-danger has-text-weight-bold is-size-5`,
        content: (
          <Fragment>
            <p className="has-text-left mb-1">
              There was a problem when uploading the data
            </p>
            {error?.message && (
              <details id="modal-details" className="has-text-left">
                <summary className="is-size-6">See Full Details</summary>
                <p>{error?.message}</p>
              </details>
            )}
          </Fragment>
        ),
      },
    })
    setLoading(false)
    setView("UPLOAD_VIEW")
  }

  const handleUploadDocuments = () => {
    setLoading(true)
    uploadToFirebase({
      data,
      headers,
      type,
      state,
      setProgress,
      errorCallback,
      successCallback: ({ skipped, uploaded, duration }) => {
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Upload Success!",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
            content: (
              <div className="has-text-centered">
                <p className="is-size-5 mb-2">
                  Uploaded {uploaded} record{uploaded > 1 && "s"}
                  {skipped > 1 &&
                    ` and skipped ${skipped} record${skipped > 1 && "s"}`}{" "}
                  in {duration}.
                </p>
                <button
                  className="button is-primary"
                  onClick={() => {
                    setView("UPLOAD_VIEW")
                    dispatch({ type: "RESET_DETAILS" })
                  }}
                >
                  Back
                </button>
              </div>
            ),
          },
        })
        setLoading(false)
      },
      config,
    })
  }

  return (
    <Layout
      seoTitle="Archiver"
      title="Verify Data"
      progress={progress}
      display={{ helpCenterBanner: false, footer: false }}
      subtitle="Verify the data before uploading to the cloud."
    >
      <Fragment>
        {data?.length === 0 ? (
          <Fragment>
            <Loading isFullScreen size={7} />
          </Fragment>
        ) : (
          <Fragment>
            <div className="px-5">
              <p className="ml-1">
                Imported <b>{data?.length} orders</b> from CSV file.
              </p>
              <Datatable
                data={tableData}
                options={{
                  customizedToolbarSelect: ({ displayData, selectedRows }) => {
                    return (
                      <ArchiverCustomToolbar
                        dispatch={dispatch}
                        orderType={type}
                        displayData={displayData}
                        selectedRows={selectedRows}
                      />
                    )
                  },
                  customIsSelectable: ({ rowData }) => {
                    if (type !== "Dispatch") return rowData?.[0]?.data === "New"
                  },
                  print: false,
                  filter: false,
                  download: false,
                  selectableRows: "multiple",
                }}
                columns={tableHeaders}
              />
            </div>
            <div className="mt-5">
              <ActionButtons
                next={{
                  loading,
                  callback: () => {
                    handleUploadDocuments()
                  },
                  label: (
                    <span>
                      <FontAwesomeIcon icon={faUpload} />
                      <span className="ml-1">Upload CSV</span>
                    </span>
                  ),
                }}
                back={{
                  label: "Back",
                  callback: () => {
                    setView("UPLOAD_VIEW")
                    dispatch({ type: "RESET_DETAILS" })
                  },
                }}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    </Layout>
  )
}

export default ArchiverVerify
