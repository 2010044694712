import React from "react"

import Message from "elements/Message"

const ErrorBuilder = ({ errors }) => {
  return (
    <div className="mb-2">
      <Message color="danger">
        <div className="is-size-6">
          The following errors has been found on the CSV:
          <ul>
            {errors?.map((error) => {
              switch (error?.code) {
                case "MC404":
                  return (
                    <li>
                      Missing columns: <b>{error?.errors?.join(", ")}</b>
                    </li>
                  )
                case "MD404":
                  return (
                    <li>
                      Missing column data:
                      <ul>
                        {error?.errors?.map((rowColumn) => (
                          <li dangerouslySetInnerHTML={{ __html: rowColumn }} />
                        ))}
                      </ul>
                    </li>
                  )
                default:
                  return <li>Unknown error detected. Please report to IT.</li>
              }
            })}
          </ul>
        </div>
      </Message>
    </div>
  )
}

export default ErrorBuilder
