import firebase from "firebase"
import moment from "moment"

/**
 *
 * @param {String} value [Raw value of the field]
 * @param {String} dataType [Data Type of the raw value based on the configuration]
 * @param {String} type [Chosen group type from the Configuration]
 * @returns {any} parsed value depending on the data type
 */
export const parseFirebaseValue = ({ value, dataType, type }) => {
  switch (dataType) {
    case "integerValue":
      return parseFloat(value) || 0
    case "booleanValue":
      return value?.toUpperCase?.() === "TRUE" ||
        value?.toUpperCase?.() === "CHECKED" ||
        value?.toUpperCase?.() === "1" ||
        value === "1"
        ? true
        : false
    case "timestampValue":
      if (!value?.toLowerCase()?.includes("cancel")) {
        let timestamp = Date.parse(value?.split(" ")?.[0])
        let date

        if (!value?.toLowerCase()?.includes("cancelled"))
          if (
            isNaN(timestamp) === false &&
            moment(value?.split(" ")?.[0])?.isValid() &&
            !value?.split(" ")?.[0]?.includes(".")
          ) {
            date = new Date(timestamp)
            return firebase.firestore.Timestamp.fromDate(date)
          } else {
            return null
          }
        return null
      }
      return null
    case "arrayValue":
      return value?.split(",").map((data) => data.trim())
    default:
    case "stringValue":
      return value?.replace(/  +/g, " ")?.trim() || ""
  }
}
